/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby';

// Helpers
import { formatNumber, filterResults } from '../../helpers/helpers';
import { checkNull } from '../../helpers/check-null';

// Layout Imports
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Component imports
import PageHeader from '../../components/page-header';
import Section from '../../components/section';
import Form from '../../components/form';
import InputField from '../../components/input-field';
import Select from '../../components/select';
import ResultListing from '../../components/result-listing';
import DownloadModal from '../../components/download-modal';
import Content, { HTMLContent } from "../../components/content-renderer";

// Document imports
import SampleReport from '../../assets/documents/example-child-benefit-report.pdf';
import ChildBenefitReport from '../../documents/child-benefit-report';

const ChildBenefit = (props) => {
    const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    // React Redux hooks
	const dispatch = useDispatch();
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.childBenefit);

    const PDFRef = useRef(null);
    
    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_YEAR' });
            dispatch({ type: 'RESET_CHILD_BENEFIT_SELECTIONS' });
        };
    }, []);

    // Get yearly child benefit before tax
    const initialChildBenefit = () => {
        if (checkNull(query.numberChildren) < 1) return 0;
        
        // Number of children times the additional rate plus the extra rate for the first child
        return (checkNull(query.numberChildren) * figures.childAdditionalBenefit + (figures.childWeeklyBenefit - figures.childAdditionalBenefit)) * 52;
    };

    // Get collective amount of income over threshold
    const amountOverThreshold = () => {
        let amount = 0;

        const checkValue = (value) => {
            if (checkNull(value) > figures.childBenefitThreshold) {
                amount = amount + checkNull(value) - figures.childBenefitThreshold;
            }
        }

        checkValue(query.yourIncome);
        checkValue(query.partnerIncome);

        return amount;
    };

    // Get amount of tax paid
    const taxAmount = () => {
        const taxPercentage = amountOverThreshold() / figures.childBenefitTaxRate;

        return initialChildBenefit() * (taxPercentage / 100);
    };

    
    // Get child benefit after tax
    const taxedChildBenefit = () => {
        const amount = initialChildBenefit() - taxAmount();

        return amount < 0 ? 0 : amount;
    }

	return (
		<Default>
			<Seo
				title={pageInfo.frontmatter.title}
				description={pageInfo.frontmatter.description}
			/>

			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
                ctaLink='/information/child-benefit'
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Benefits', url: '/benefits'},
                ]}
			/>

            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='number-children'
                                labelText='Number of children'
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                                type='number'
                                placeholder='0'
                                min={0}
                                onChange={(event) => {
                                    dispatch({ type: 'SET_CHILD_BENEFIT_NUMBER_CHILDREN', payload: event.target.value});
                                }}
                                required
                                value={query.numberChildren}
                            />

                            <Select
                                labelText="Tax year"
                                id="tax-year"
                                onChange={(event) => dispatch({
                                    type: 'SET_YEAR',
                                    payload: event.target.value,
                                })}
                                value={figures.selectedYear}
                                required
                            >
                                <Select.Option value='2024'>2024/25</Select.Option>
                                <Select.Option value='2023'>2023/24</Select.Option>
                                <Select.Option value='2022'>2022/23</Select.Option>
                                <Select.Option value='2021'>2021/22</Select.Option>
                                <Select.Option value='2020'>2020/21</Select.Option>
                                <Select.Option value='2019'>2019/20</Select.Option>
                                <Select.Option value='2018'>2018/19</Select.Option>
                            </Select>
                        </Form.Row>

                        <Form.Row>
                            <InputField
                                id='your-gross-income'
                                labelText='Your gross yearly income'
                                placeholder='0'
                                modalHeading={definitions[1].frontmatter.title}
                                modalContent={<PageContent content={definitions[1].html} />}
                                symbol='currency'
                                min={0}
                                onChange={(event) => {
                                    dispatch({ type: 'SET_CHILD_BENEFIT_YOUR_INCOME', payload: event.target.value });
                                }}
                                required
                                value={query.yourIncome}
                            />

                            <InputField
                                id='partner-gross-income'
                                labelText="Partner's gross yearly income"
                                placeholder='0'
                                symbol='currency'
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                                min={0}
                                onChange={(event) => {
                                    dispatch({ type: 'SET_CHILD_BENEFIT_PARTNER_INCOME', payload: event.target.value });
                                }}
                                required
                                value={query.partnerIncome}
                            />
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>

            <Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
                <ResultListing
                    select={[
                        {name: 'Year', value: 'year'},
                        {name: 'Month', value: 'month'},
                        {name: 'Week', value: 'week'},
                        {name: 'Day', value: 'day'},
                        {name: 'Hour', value: 'hour'},
                    ]}
                    selectID='results-filter'
                    selectOnChange={(event) => {
                        dispatch({ type: 'SET_CHILD_BENEFIT_FILTER_RESULTS', payload: event.target.value });
                    }}
                    summary={
                        <>
                            {taxAmount() !== 0 && (
                                <p>
                                    You are entitled to <span className='result-listing__summary--emphasis'>{formatNumber(initialChildBenefit())}</span> per year, minus <span className='result-listing__summary--emphasis'>{formatNumber(taxAmount())}</span> as you or your partner earn more than {formatNumber(figures.childBenefitThreshold)} per year
                                </p>
                            )}
                            
                            <DownloadModal
                                sample={SampleReport}
                                elementRef={PDFRef}
                                PDFName='child-benefit-report'
                                PDFTitle={pageInfo.frontmatter.title}
                            />
                        </>
                    }
                >
                    <ResultListing.Item
                        heading='Child benefit payment'
                        result={filterResults(taxedChildBenefit(), query.filterResults, 7, 24)}
                        subheading={`per ${query.filterResults}`}
                    />
                </ResultListing>
            </Section>

            <ChildBenefitReport
                headerImage={`${withPrefix("/")}img/${headerImage}`}
                heading={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
                definitions={definitions}
                initialResult={initialChildBenefit()}
                taxedResult={taxedChildBenefit()}
                taxAmount={taxAmount()}
                ref={PDFRef}
            />
		</Default>
	);
};

export default ChildBenefit;

export const ChildBenefitQuery = graphql`
    query ChildBenefitQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                image {
                    relativePath
                }
                description
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/partner|src/definitions/gross-income|src/definitions/eligible-children/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;