/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Helpers
import { formatNumber } from '../helpers/helpers';

// Component imports
import PDFLayout from '../layouts/pdf-layout';
import PDFSummary from '../layouts/pdf-layout/summary';
import Table from '../components/table';

const ChildBenefitReport = forwardRef(({
    headerImage,
    heading,
    description,
    definitions,
    initialResult,
    taxedResult,
    taxAmount,
}, ref) => {
    // React Redux hooks
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.childBenefit);

    const taxYear = `${figures.selectedYear}/${parseInt(figures.selectedYear.slice(-2), 10) + 1}`;

	return (
	    <PDFLayout
            headerImage={headerImage}
            heading={heading}
            description={`${description} - this document includes a full breakdown of your personal calculation results and other relevant information`}
            definitions={definitions}
            ref={ref}
        >
            <h3>1. Your figures &amp; selections</h3>

            <p>The following shows a summary of all figures you entered into our {heading} and any other selections you've made.</p>
            
            <PDFSummary>
                <PDFSummary.Item>
                    <strong>Number of children:</strong> {query.numberChildren ? query.numberChildren : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Tax year:</strong> {taxYear}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Your gross yearly income:</strong> {query.yourIncome ? formatNumber(query.yourIncome) : '-'}
                </PDFSummary.Item>

                <PDFSummary.Item>
                    <strong>Partner's gross yearly income:</strong> {query.partnerIncome ? formatNumber(query.partnerIncome) : '-'}
                </PDFSummary.Item>
            </PDFSummary>

            <hr />

            <h3>2. Your results</h3>

            {taxAmount === 0 ? (
                <PDFSummary.Item>
                    <strong>You are entitled to a Child Benefit payment of</strong> {formatNumber(taxedResult)} <strong>per year</strong>
                </PDFSummary.Item>
            ) : (
                <PDFSummary.Item>
                    <strong>You are entitled to a Child Benefit payment of</strong> {formatNumber(taxedResult)} <strong>per year. Your payment has been reduced as you or your partner earn more than</strong> {formatNumber(figures.childBenefitThreshold)} <strong>per year</strong> ({formatNumber(initialResult)}<strong> total - </strong>{formatNumber(taxAmount)}<strong> tax = </strong>{formatNumber(taxedResult)})
                </PDFSummary.Item>
            )}

            <h4>Full breakdown:</h4>

            <Table>
                <Table.Header>
                    <th></th>
                    <th>Yearly</th>
                    <th>Monthly</th>
                    <th>Weekly</th>
                    <th>Daily</th>
                    <th>Hourly</th>
                </Table.Header>
                <tbody>
                    <tr>
                        <td>Entitlement</td>
                        <td>{formatNumber(initialResult)}</td>
                        <td>{formatNumber(initialResult / 12)}</td>
                        <td>{formatNumber(initialResult / 52)}</td>
                        <td>{formatNumber(initialResult / 365)}</td>
                        <td>{formatNumber(initialResult / 365 / 24)}</td>
                    </tr>
                    <tr>
                        <td>Tax paid</td>
                        <td>{formatNumber(taxAmount)}</td>
                        <td>{formatNumber(taxAmount / 12)}</td>
                        <td>{formatNumber(taxAmount / 52)}</td>
                        <td>{formatNumber(taxAmount / 365)}</td>
                        <td>{formatNumber(taxAmount / 365 / 24)}</td>
                    </tr>
                </tbody>
                <Table.Footer>
                    <tr>
                        <td>Payment</td>
                        <td>{formatNumber(taxedResult)}</td>
                        <td>{formatNumber(taxedResult / 12)}</td>
                        <td>{formatNumber(taxedResult / 52)}</td>
                        <td>{formatNumber(taxedResult / 365)}</td>
                        <td>{formatNumber(taxedResult / 365 / 24)}</td>
                    </tr>
                </Table.Footer>
            </Table>

            <hr />

            <h3>3. How it works</h3>

            <p>If you are responsible for one or more children you can claim Child Benefit from the government to help you pay for anything you need and boost your household budget.</p>

            <p>The amount you receive in Child Benefit is reduced depending on your income.</p>

            <p>Only one person can receive Child Benefit for a child and is paid every 4 weeks (normally on a Monday or Tuesday) with no limit to how many children you can claim for.</p>

            <h4>3.1. How much will I be paid?</h4>

            <p>You will recieve a weekly payment of {formatNumber(figures.childWeeklyBenefit)} for your eldest or only child and {formatNumber(figures.childAdditionalBenefit)} per additional child.</p>
            
            <p>Example weekly Child Benefit payments for the tax year {taxYear}:</p>

            <Table style={{width: '50%'}}>
                <Table.Header>
                    <th>Number of children</th>
                    <th>Weekly payment</th>
                </Table.Header>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>{formatNumber(figures.childWeeklyBenefit)}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>{formatNumber(figures.childWeeklyBenefit + figures.childAdditionalBenefit)}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 2))}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 3))}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>{formatNumber(figures.childWeeklyBenefit + (figures.childAdditionalBenefit * 4))}</td>
                    </tr>
                </tbody>
            </Table>
            
            <h5>State Pension &amp; Child Benefit payments</h5>
            
            <p>If you are claiming for a child who is under 12 and you’re unemployed or do not earn enough to pay National Insurance contributions, Child Benefit can give you National Insurance credits. National Insurance credits count towards your State Pension so you do not have gaps in your National Insurance record.</p>

            <h5>If a family splits up</h5>
            
            <ul>
                <li>If your family splits up and you have one child; you will still recieve a payment of {formatNumber(figures.childWeeklyBenefit)} per week for the eldest child.</li>

                <li>If you have 2 children and one stays with you and the other stays with your ex-partner; you will both get {formatNumber(figures.childWeeklyBenefit)} per week for each child.</li>

                <li>If you and your ex-partner make a claim for the same child only one of you will recieve a Child Benefit payment for them.</li>

                <li>If you have any other children who are elibile for Child Benefit payments then you will recieve {formatNumber(figures.childAdditionalBenefit)} per week for each child.</li>
            </ul>

            <h5>If two families join together</h5>
            
            <p>If two families join together then the eldest child in the new family will be eligible for the higher payment of {formatNumber(figures.childWeeklyBenefit)} and any other children who are eligible will recieve {formatNumber(figures.childAdditionalBenefit)}.</p>
            
            <h4>3.2. Earnings over {formatNumber(figures.childBenefitThreshold)}</h4>

            <p>If you or your partner earns over {formatNumber(figures.childBenefitThreshold)} per year then the amount of Child Benefit you are entitled to is reduced.</p>

            <p>Your Child Benefit payments are reduced by 1% of your entitlement for every £{figures.childBenefitTaxRate} over the {formatNumber(figures.childBenefitThreshold)} threshold that you earn, up to a maximum income of {formatNumber(figures.childBenefitUpperLimit)} where you will recieve no Child Benefit payments.</p>

            <p>If you earn over {formatNumber(figures.childBenefitThreshold)} then any reduction in your Child Benefit payments will be paid back to the government in tax.</p>

            <h4>3.3. How to claim</h4>

            <p>You can claim Child Benefit as soon as your child is born or an eligible child starts living with you.</p>

            <p>To make a claim, download a form from <a href='https://www.gov.uk/government/publications/child-benefit-claim-form-ch2'>HM Revenue and Customs (HMRC)</a> or phone the Child Benefit Office to send you a claim form: <a href='tel:0300 200 3100'>0300 200 3100</a></p>
            
            <h4>3.4. Changes in circumstances</h4>

            <p>If your personal circumstances change at any point whilst claiming Child Benefit, you must report these to the Child Benefit Office. These include changes to your:</p>

            <ul>
                <li>family life, for example getting married</li>
                <li>child’s life, for example leaving education or training</li>
                <li>if you want someone else to claim Child Benefit, for example, your partner</li>
                <li>if you wish to stop your payments at any time</li>
            </ul>

            <h5>If your child starts work or receives benefits in their own right</h5>
            
            <p>You’ll stop receiving Child Benefit payments immediately if:</p>

            <ul>
                <li>your child starts paid work for at least 24 hours per week and is no longer in approved education or training</li>
                <li>your child starts an apprenticeship in England</li>
                <li>your child starts receiving certain benefits in their own right; e.g. Income Support, Employment and Support Allowance or tax credits</li>
            </ul>

            <h4>3.5. Challenging a decision</h4>
            <p>You can challenge a decision about your claim - this is called asking for <a href='https://www.gov.uk/mandatory-reconsideration'>mandatory reconsideration</a>.</p>
            
            <h4>3.6. Other information</h4>

            <p>If you choose to claim Child Benefit:</p>

            <ul>
                <li>you can get <a href='https://www.gov.uk/national-insurance-credits/overview'>National Insurance credits</a> which count towards your State Pension</li>
                <li>your child will automatically get a National Insurance number when they’re 16 years old</li>
            </ul>
            
            <p>If you choose not to get Child Benefit payments, you should still fill in and send off the claim form.</p>

            <h5>Living abroad</h5>
            
            <p>If you wish to move outside of the UK you may still be eligible for Child Benefit payments for certain countries or if you’re a Crown servant. <a href='https://www.gov.uk/child-benefit-abroad'>Find out more</a>.</p>

            <h5>If you’ve moved to the UK</h5>

            <p>If you have moved to the UK and you have a ‘Right to Reside’ you will be elibile to receive Child Benefit payments.</p>

            <hr />
        </PDFLayout>
	);
});

ChildBenefitReport.propTypes = {
    headerImage: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    definitions: PropTypes.array.isRequired,
    initialResult: PropTypes.number.isRequired,
    taxedResult: PropTypes.number.isRequired,
    taxAmount: PropTypes.number.isRequired,
};

export default ChildBenefitReport;